



































































import Vue from "vue";
import LocationChooser from "@/components/desktop/LocationChooser.vue";
import FileInput from "@/components/desktop/FileInput.vue";
export default Vue.extend({
  name: "adpost-form",
  components: {
    "location-chooser": LocationChooser,
    "file-input": FileInput,
  },
  data: () => ({
    form: {
      email: "",
      name: "",
      food: null,
      checked: [],
    },
    mobiles: [
      { text: "Please select brand", value: null },
      "Samsung",
      "Redmi",
      "Oppo",
      "Vivo",
    ],
    show: true,
  }),

  methods: {
    onSubmit(evt: Event) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt: Event) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
});
