


















import Vue from "vue";
import MobileAdForm from "@/components/MobileAdForm.vue";
import BikesAdForm from "@/components/BikesAdForm.vue";
import CarsAdForm from "@/components/CarsAdForm.vue";
import SignInBtn from "@/components/SignInBtn.vue";


export default Vue.extend({

	name: "about",
	components : {
		"mobile-ad-form" : MobileAdForm,
		"bikes-ad-form" : BikesAdForm,
		"cars-ad-form" : CarsAdForm,
		"signin-btn" : SignInBtn,
	
		
	}
});
